.order-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.sub-order:not(:last-child) {
    margin-bottom: 5rem;
}
.select-suborder {
    display: flex;
    align-items: center;
    gap: 1rem;
}
.select-suborder>.p-dropdown {
    width: 12.5rem;
}
.leftSide {
    width: 50%;
}
.rightSide {
    width: 50%;
}
.infoCardTitle {
    margin: 0 0 0 16px;
    font-size: 24px;
}
.orderTitle {
    margin: 0 0 0 16px;
    font-size: 26px;
}
.infoCard {
    margin: 16px;
    padding: 16px;
    border: 1px solid #000;
}
/* table row */
.tableRow {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.5rem 1rem -0.5rem;
}

/* label */
.infoCardLabel {
    display: flex;
    align-items: unset;
    padding: 0;
    font-weight: bold;
}

/* value */
.infoCardValue {
    padding: 0;
}
.infoCardValue input {
    width: 100%;
}
.changeableInput {
    background-color: lightblue;
}
.changeableSelect {
    background-color: lightblue;
}
#statusSelect {
    width: 100%;
    margin-bottom: 2px;
}

.statusSelectBtn {
    display: block;
}
.disabledCalendar {
    pointer-events: none;
    background-color: #000;
}

/* changeable data */
.changeableDataFieldset {
    position: relative;
}
.changeableDataReset {
    margin-right: 10px;
}
.changeableDataModal {
    padding: 16px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(173, 216, 230, 0.95);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.changeableDataModal h3 {
    margin: 0 0 8px 0;
}
.changeableDataModal h4 {
    margin: 0 0 5px 0;
}
.changeableDataModal textarea {
    display: block;
    min-width: 300px;
    min-height: 70px;
    margin-bottom: 5px;
    padding: 5px;

    font-size: 16px;
}
.changeableDataModalConfirm {
    margin-left: 10px;
}

/* timeline */
.timelineTitle {
    margin-left: 16px;
}
.p-timeline.p-component.p-timeline-left.p-timeline-horizontal.p-timeline-left {
    flex-wrap: wrap;
    margin-left: 16px;
}
.p-timeline-event-opposite {
    display: none;
}
.p-timeline-event {
    flex: 0 !important;
    min-width: 130px !important;
    min-height: 40px !important;
    margin-bottom: 5px;
}
.p-timeline-event-content {
    flex: 0 !important;
    margin: 0 5px 0 0;
    padding: 2.5px 5px 0 0 !important;
    font-size: 14px;
}
.p-timeline-event:hover .p-timeline-event-marker {
    background-color: #2196F3;
}
button {
    cursor: pointer;
}
.tooltip-report {
    min-width: 560px;
}
@media (min-width: 768px) {
    .infoCardLabel {
        flex: 0 0 auto;
        width: 30%;
    }
    .infoCardValue {
        flex: 0 0 auto;
        width: 50%;
    }
}
