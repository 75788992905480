.box {
  display: block;
  width: 1000px;
  margin: 32px auto;
}

.title {
  margin: 0;
  text-align: center;
}

.message {
  margin: 8px 0 0;
  color: red;
  text-align: center;
  text-transform: lowercase;
  text-decoration: underline;
}

.form {
  margin-top: 32px;
}

.destination {
  display: flex; 
  width: 100%;
  margin-bottom: 16px;
  justify-content: space-between;
}

.where {
  width: 470px;
}

.pickup {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pickup>div {
  width: 210px;
}

.inputs {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  margin-bottom: 1rem;
}

.input span {
  width: 100%;
}

.input input {
  width: 100%;
}

.buttons {
  display: flex;
  justify-content: flex-end;
}

.button {
  min-width: 120px;
  margin-left: 16px;
}

.button button {
  width: 100%
}

.resultTitle {
  text-align: center;
}

.subTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  cursor: pointer;
}

.result,
.all-result {
  width: 1000px;
  margin: 16px auto;
  border: 1px solid hotpink;
}

.all-result th {
  padding: 0.5rem !important;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.all-result td {
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.oformitBtn {
  width: 1000px;
  margin: 0 auto;
  text-align: end;
}

.fieldset {
  padding: 1rem;
  width: 1000px;
  font-size: 0.9rem;
  border: 1px solid hotpink;
  background-color: #f8f9fa;
}
.fieldset legend {
  font-size: 1.1rem;
  font-weight: 600;
}
.fieldset input {
  font-size: 1rem;
  padding: 0.3rem 0.5rem;
}

.card {
  display: block;
  width: fit-content;
  margin: 1rem auto 0;
}
.createBtn {
  width: 1000px;
  margin: 1rem auto 0;
  text-align: end;
}
.checkboxes {
  display: flex;
  flex-direction: column;
}
.checkboxes>div {
  width: fit-content;
  padding-right: 0.5rem;
}