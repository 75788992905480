.product-category-block {
    padding: 15px;
}

.product-category-head h2 {
    margin: 0 0 29px;
}

.product-category-head label {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    margin-bottom: 5px;
}

.product-category-add-btn {
    margin: 16px 0 25px;
    border: none;
    color: #2196F3;
    font-size: 14px;
}

.product-category-add-btn:hover {
    cursor: pointer;
}

.product-category-table {
    font-size: 12px;
    padding: 5px;
}

.product-category-table th, .product-category-table td {
    overflow-wrap: break-word;
}

.product-category-modal {
    display: grid;
    grid-template-columns: 1.5fr 1.5fr 1.5fr;
    grid-template-rows: 1fr;
    grid-gap: 2rem;
    grid-row-gap: 0px;
    color: #666564;
}

.product-category-modal-h3 {
    margin-top: 30px;
    margin-bottom: 2rem;
    font-weight: 600;
    font-size: 20px;
}

.product-category-checkboxes {
    display: grid;
    grid-template-columns: repeat(6, auto);
    grid-column-gap: 1rem;
}

.product-category-checkboxes label {
    margin-left: 10px;
}

.product-category-carrier {
    margin-bottom: 15px;
}

.product-category-carrier label:hover {
    cursor: pointer;
}

.product-category-buttons {
    display: flex;
    justify-content: flex-start;
}

.product-category-buttons>.product-category-button {
    min-width: 120px;
    margin-left: 0;
    margin-right: 16px;
}

.product-category-button button {
    width: 100%;
}

.p-datatable .p-datatable-tbody > tr > td {
    border: 1px solid #e9ecef !important;
}