
.datatable-doc-demo .p-paginator .p-paginator-current {
    margin-left: auto;
}

.datatable-doc-demo .p-progressbar {
    height: .5rem;
    background-color: #D8DADC;
}

.datatable-doc-demo .p-progressbar .p-progressbar-value {
    background-color: #607D8B;
}

.datatable-doc-demo .table-header {
    display: flex;
    justify-content: space-between;
}

.datatable-doc-demo .p-datepicker {
    min-width: 25rem;
}

.datatable-doc-demo .p-datepicker td {
    font-weight: 400;
}

.datatable-doc-demo .p-datatable.p-datatable-customers .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
}

.datatable-doc-demo .p-datatable.p-datatable-customers .p-paginator {
    padding: 1rem;
}

.datatable-doc-demo .p-datatable.p-datatable-customers .p-datatable-thead > tr > th {
    text-align: left;
}

.datatable-doc-demo .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td {
    cursor: auto;
}

.datatable-doc-demo .p-datatable.p-datatable-customers .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
}

.datatable-doc-demo .p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

.table-cell {
    display: flex;
    align-items: center;
}

.link {
    color: #0b7ad1;
}
.link:hover {
    color: #0b7ad1;
    text-decoration: underline;
}
.p-toast .p-toast-message-text {
    width: 280px;
}
.p-toast .p-toast-detail {
    white-space: pre;
}

@media screen and (max-width: 960px) {
    .datatable-doc-demo .p-datatable.p-datatable-customers .p-datatable-thead > tr > th,
    .datatable-doc-demo .p-datatable.p-datatable-customers .p-datatable-tfoot > tr > td {
        display: none !important;
    }

    .p-datatable table {
        table-layout: unset !important;
        width: calc(100vw - 40px) !important;
    }

    .datatable-doc-demo .p-datatable.p-datatable-customers .p-datatable-tbody > tr {
        border-bottom: 1px solid var(--layer-2);
    }

    .datatable-doc-demo .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        float: left;
        clear: left;
        border: 0 none;
    }

    .datatable-doc-demo .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
        padding: .4rem;
        min-width: 30%;
        display: inline-block;
        margin: -.4rem 1rem -.4rem -.4rem;
        font-weight: bold;
    }

    .datatable-doc-demo .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td .p-progressbar {
        margin-top: .5rem;
    }
}

#popup_menu {
    left: inherit !important;
    top: inherit !important;
}

.order__link {
    text-decoration: underline !important;
    color: blue;
}

.order__link:visited, span.visited {
    color: purple;
}
